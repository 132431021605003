
/**
 * First, we will load all of this project's Javascript utilities and other
 * dependencies. Then, we will be ready to develop a robust and powerful
 * application frontend using useful Laravel and JavaScript libraries.
 */

try {
    window.$ = window.jQuery = require('jquery');
    window.toastr = require('toastr');
    window.bootstrap = require('bootstrap-sass');
    require('admin-lte');
    window.icheck = require('icheck');
    window.select2 = require('select2');
} catch (e) {}
// require('./bootstrap');
